/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import {getSessionStoragedCurrentOperator, getStoragedCurrentOperator} from "../../../../app/modules/auth";
import './MobileNav.css';

const MobileNav = () => {
    const intl = useIntl();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState('');
    const sessionOperator = getSessionStoragedCurrentOperator();
    let operator = getStoragedCurrentOperator();
    let isOperator = operator || sessionOperator;

    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location.pathname]);

    const menuConfig = {
        composer: [
            { path: '/composer/bet-card/quicktip', labelId: 'SIDEBAR.SUBNAVIGATION.QUICKTIP' },
            { path: '/composer/bet-card/smart-bet', labelId: 'SIDEBAR.SUBNAVIGATION.SMARTBET' },
            { path: '/composer/bet-card/superbets', labelId: 'SIDEBAR.SUBNAVIGATION.SUPERBET' },
            { path: '/composer/bet-card/quickbet', labelId: 'SIDEBAR.SUBNAVIGATION.QUICKBET' },
            { path: '/composer/bet-card/jackpot', labelId: 'SIDEBAR.SUBNAVIGATION.JACKPOT' },
        ],
        dashboard: [
            { path: '/composer/bet-card/quicktip', labelId: 'SIDEBAR.NAVIGATION.BETCOMPOSER' },
            { path: '/betengine/tickets', labelId: 'SIDEBAR.NAVIGATION.BETENGINE' },
            { path: '/publisher/edits', labelId: 'SIDEBAR.NAVIGATION.BETPUBLISHER' },
            { path: '/sportsdata/settings/leagues', labelId: 'SIDEBAR.NAVIGATION.SPORTSDATA' },
            // { path: '/account/overview', labelId: 'PROFILE.OVERVIEW.NAVIGATION.PROFILE' },
        ],
        sportsdata: [
            { path: '/sportsdata/settings/countries', labelId: 'SIDEBAR.SUBNAVIGATION.COUNTRIES' },
            { path: '/sportsdata/settings/leagues', labelId: 'SIDEBAR.SUBNAVIGATION.COMPETITIONS' },
            { path: '/sportsdata/settings/teams', labelId: 'SIDEBAR.SUBNAVIGATION.TEAMS' },
            { path: '/sportsdata/settings/results', labelId: 'SIDEBAR.SUBNAVIGATION.RESULTS' },
        ],
    };

    const getMenuItems = () => {
        if (currentLocation.includes('composer')) {
            return menuConfig.composer;
        } else if (currentLocation.includes('dashboard')) {
            return menuConfig.dashboard;
        } else if (currentLocation.includes('betengine')) {
            return menuConfig.dashboard;
        } else if (currentLocation.includes('publisher')) {
            return menuConfig.dashboard;
        } else if (currentLocation.includes('sportsdata')) {
            return menuConfig.sportsdata;
        }  else if (currentLocation.includes('account')) {
            return menuConfig.dashboard;
        }

        return [];
    };

    const menuItems = getMenuItems();

    return (
        <>
            {isOperator && menuItems.length > 0 ? (
                <div className='influencer-mobile-menu-wrapper'>
                    <ul className='menu menu-gray-600 menu-hover-primary fw-semibold influencer-mobile-menu'>
                        {menuItems.map((item) => (
                            <li className='menu-item' key={item.path}>
                                <Link
                                    to={item.path}
                                    className={`menu-link px-2 ${currentLocation.includes(item.path) ? 'active' : ''}`}
                                >
                                    {intl.formatMessage({ id: item.labelId })}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </>
    );
}

export {MobileNav};
